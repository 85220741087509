import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"margin-top":"1rem","font-weight":"bold"} }
const _hoisted_2 = { class: "doctype workflow_section" }
const _hoisted_3 = { class: "head_footer" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "status" }
const _hoisted_6 = { class: "workflow_amount" }
const _hoisted_7 = { class: "head_footer" }
const _hoisted_8 = { style: {"color":"black"} }
const _hoisted_9 = { style: {"color":"lightcoral"} }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { style: {"margin-top":"1rem","font-weight":"bold"} }
const _hoisted_12 = { class: "doctype workflow_section" }
const _hoisted_13 = { class: "head_footer" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "indicator-pill whitespace-nowrap orange" }
const _hoisted_16 = { class: "" }
const _hoisted_17 = {
  key: 0,
  class: "flex"
}
const _hoisted_18 = { class: "text-secondary w-25" }
const _hoisted_19 = { class: "head_footer" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_card = _resolveComponent("el-card")
  const _component_el_button = _resolveComponent("el-button")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.data.undone_list_summary, (outerItem, outerIndex) => {
      return (_openBlock(), _createElementBlock("div", { key: outerIndex }, [
        _createElementVNode("div", _hoisted_1, _toDisplayString($setup.t(outerIndex)), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(outerItem, (item, innerIndex) => {
            return (_openBlock(), _createElementBlock("div", { key: innerIndex }, [
              _createVNode(_component_el_card, {
                shadow: "hover",
                class: "mx-2 my-2",
                style: _normalizeStyle([{"width":"360px"}, $setup.workflow_style[outerIndex]]),
                onClick: $event => ($setup.show_details(item, innerIndex, $event))
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", null, [
                      _createElementVNode("a", {
                        href: item.url,
                        target: "_blank"
                      }, _toDisplayString(item.name), 9 /* TEXT, PROPS */, _hoisted_4)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["indicator-pill whitespace-nowrap", $setup.btn_class_map[item.workflow_state]])
                      }, [
                        _createElementVNode("span", null, _toDisplayString($setup.t(item.workflow_state)), 1 /* TEXT */)
                      ], 2 /* CLASS */)
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(item.status), 1 /* TEXT */)
                  ])
                ]),
                footer: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", null, [
                      _createTextVNode(" 记账日："),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(item.posting_date), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", null, [
                      _createTextVNode(" 最后更新："),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(item.modified), 1 /* TEXT */)
                    ])
                  ])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(item.grand_total.toLocaleString('zh-CN', { style: 'currency', currency: 'CNY' })), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style", "onClick"])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]))
    }), 128 /* KEYED_FRAGMENT */)),
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList($setup.request_doctypes, (doctype, index) => {
      return _createElementVNode("div", { key: index }, [
        ($setup.request_docs[doctype])
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, _toDisplayString($setup.t(doctype)), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.request_docs[doctype], (doc, doc_index) => {
                  return (_openBlock(), _createElementBlock("div", { key: doc_index }, [
                    (!$setup.handled_doc.includes(doc.name))
                      ? (_openBlock(), _createBlock(_component_el_card, {
                          key: 0,
                          shadow: "hover",
                          class: "mx-2 my-2",
                          style: _normalizeStyle([{"width":"360px"}, $setup.workflow_style[doctype]])
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", null, [
                                _createElementVNode("a", {
                                  href: $setup.get_doc_link(doctype, doc.name),
                                  target: "_blank"
                                }, _toDisplayString(doc.name), 9 /* TEXT, PROPS */, _hoisted_14)
                              ]),
                              _createElementVNode("div", null, [
                                _createElementVNode("span", _hoisted_15, [
                                  _createElementVNode("span", null, _toDisplayString($setup.t(doc.status) || $setup.t('Draft')), 1 /* TEXT */)
                                ])
                              ])
                            ])
                          ]),
                          footer: _withCtx(() => [
                            _createElementVNode("div", _hoisted_19, [
                              _createVNode(_component_el_button, {
                                type: "danger",
                                size: "small"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('Delete')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_el_button, {
                                type: "primary",
                                size: "small",
                                onClick: $event => ($setup.submit_doc(doctype, doc, $event))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('Submit')), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_16, [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(doc, (value, key) => {
                                return (_openBlock(), _createElementBlock("div", { key: key }, [
                                  (!['name'].includes(key))
                                    ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                                        _createElementVNode("div", _hoisted_18, _toDisplayString($setup.t($setup.request_labels[doctype][key])) + ":", 1 /* TEXT */),
                                        _createElementVNode("div", null, _toDisplayString($setup.t(value)), 1 /* TEXT */)
                                      ]))
                                    : _createCommentVNode("v-if", true)
                                ]))
                              }), 128 /* KEYED_FRAGMENT */))
                            ])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["style"]))
                      : _createCommentVNode("v-if", true)
                  ]))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ])
    }), 64 /* STABLE_FRAGMENT */))
  ], 64 /* STABLE_FRAGMENT */))
}