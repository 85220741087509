function getFormatter(fieldtype, cellValue) {
	// 格式化表格中的单元格数据
	if (fieldtype === 'Currency' || in_list(['Currency', 'Percent'], fieldtype)) {
		cellValue = cellValue || 0;
		return formatCurrency(cellValue)
	} else {
		return t(cellValue);
	}
}

function get_url(fieldtype, cellValue,options) {
	cellValue = cellValue || '/';
	let doctype = options
	return frappe.utils.get_form_link(doctype,cellValue)
}

function formatCurrency(value) {
	value = value || 0;
	return value.toLocaleString('zh-CN',
		{
			minimumFractionDigits: 2, // 设置小数点后至少显示的位数
			maximumFractionDigits: 2  // 设置小数点后最多显示的位数
		}
	);
}

function t(txt, replace, context = null) {
	// 翻译函数封装
	return __(txt, replace, context)
}

function disabledDate(time) {
	return time.getTime() > Date.now()
}

function guessClass(value) {
	if (status_color_map[value]) {
		return status_color_map[value]
	} else {
		return 'gray'
	}
}

const shortcuts = [
	{
		text: __('Today'),
		value: new Date(),
	},
	{
		text: __('Yesterday'),
		value: () => {
			const date = new Date()
			date.setTime(date.getTime() - 3600 * 1000 * 24)
			return date
		},
	},
	{
		text: __('1 weeks ago'),
		value: () => {
			const date = new Date()
			date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
			return date
		},
	},
	{
		text: __('Month Start'),
		value: () => {
			return frappe.datetime.month_start()
		},
	},
	{
		text: __('Financial Year Begins On'),
		value: () => {
			return erpnext.utils.get_fiscal_year(frappe.datetime.get_today(), true)[1]
		},
	},
	{
		text: __('1 year ago'),
		value: () => {
			const date = new Date()
			date.setTime(date.getTime() - 3600 * 1000 * 24 * 365)
			return date
		},
	},
]

const status_color_map = {
	// Comment
	'Completed': 'green',
	'Complete': 'green',
	'Open': 'green',
	'Closed': 'red',
	'Draft': 'pink',
	'Pending': 'orange',
	'Cancelled': 'red',
	// Stocking
	'On Hold': 'yellow',
	'Overdue': 'red',
	'To Deliver and Bill': 'purple',
	'To Receive and Bill': 'purple',
	'To Bill': 'pink',
	'To Deliver': 'orange',
	'To Receive': 'orange',
	'Delivered': 'green',
	'Partially Ordered': 'yellow',
	'Partially Received': 'purple',
	'Ordered': 'green',
	'Ignore Sales Invoice': 'green',
	'Return Issued': 'purple',
	// accounting
	'Partly Paid': 'green',
	'Paid': 'blue',
	'Requested': 'green',
	'Initiated': 'pink',
	'Partially Paid': 'orange',
	'To Be Claimed': 'purple',
	'Complete By Special Funds': 'dark blue',
	'Unpaid': 'purple',
	'Not Started': 'orange',
	'Claimed': 'green',
	'Returned': 'green',
	'Partly Claimed and Returned': 'green',
	// Attendance
	'Absent': 'red',
	'Half Day': 'orange',
	// CRM
	'Lost Quotation': 'pink',
	'Opportunity': 'purple',
	'Converted': 'green',
}

// 设置菜单
const menu_list = [
	{
		value: 'report',
		label: 'Report',
	},
	{
		value: 'selling',
		label: 'Selling',
		sub_menu: [
			{
				value: 'sales_order',
				label: 'Sales Order',
			},
			{
				value: 'delivery_note',
				label: 'Delivery Note',
			},
			{
				value: 'sales_invoice',
				label: 'Sales Invoice',
			},
			{
				value: 'payment_entry',
				label: 'Payment Entry',
			},
		]
	},
	{
		value: 'buying',
		label: 'Buying',
		sub_menu: [
			{
				value: 'sales_order',
				label: 'Sales Order',
			},
			{
				value: 'sales_invoice',
				label: 'Sales Invoice',
			},
			{
				value: 'purchase_order',
				label: 'Purchase Order',
			},
			{
				value: 'purchase_invoice',
				label: 'Purchase Invoice',
			}
		]
	},
	{
		value: 'expenses',
		label: 'Expenses',
		sub_menu: [
			{
				value: 'expense_claim',
				label: 'Expense Claim',
			},
			{
				value: 'employee_advance',
				label: 'Employee Advance',
			},
		]
	},
	{
		value: 'hr',
		label: 'Human Resources',
		sub_menu: [
			{
				value: 'attendance',
				label: 'Attendance',
			},
			{
				value: 'Salary Slip',
				label: 'Salary Slip',
			},
		]
	}
]

const business_division_options = [
	{ value: '天津', label: __('天津') },
	{ value: '深圳', label: __('深圳') },
	{ value: '融资池', label: __('融资池') },
	{ value: '西安', label: __('西安') },
]

// 初始化tabs
const tabs = [
	{
		value: 'summary',
		label: __('Summary'),
		custom_filters: [
			{
				"fieldname": "ignore_internal_customer",
				"label": __("Ignore Internal Customer"),
				"fieldtype": "Check",
				"default": true,
			},
		]
	},
	{
		value: 'sales',
		label: __('Profit Analysis'),
		report_name: 'Profit Analysis',
		custom_filters: [
			// {
			// 	"fieldname": "voucher_group",
			// 	"label": __("Voucher Group"),
			// 	"fieldtype": "Select",
			// 	"default": "Selling",
			// 	"options": [
			// 		{ value: "", label: __('') },
			// 		{ value: "Selling", label: __('Selling') },
			// 		{ value: "Buying", label: __('Buying') },
			// 		{ value: "Expenses", label: __('Expenses') },
			// 		{ value: "Stock Entry", label: __('Stock Entry') },
			// 		{ value: "Journal Entry", label: __('Journal Entry') },
			// 	],
			// },
			{
				"fieldname": "ignore_internal_customer",
				"label": __("Ignore Internal Customer"),
				"fieldtype": "Check",
				"default": 1,
			},
		]
	},
	// {
	// 	value: 'project_profit',
	// 	label: __('Gross Profit By Project'),
	// 	report_name: 'Gross Profit By Project',
	// },
	{
		value: 'purchase_order_tracking',
		label: __('Purchase Order Tracking'),
		report_name: 'Purchase Order Tracking',
		custom_filters: [
			{
				fieldname:"supplier_group",
				label: __("Supplier Group"),
				fieldtype: "Link",
				options: "Supplier Group",
			},
			{
				"fieldname": "ignore_internal_supplier",
				"label": __("Ignore Internal Supplier"),
				"fieldtype": "Check",
				"default": 1,
			},
			{
				"fieldname": "ignore_zero_balance",
				"label": __("Ignore Zero Balance"),
				"fieldtype": "Check",
				"default": 1,
			},
		]
	},
	// {
	// 	value: 'receivable',
	// 	label: __('Accounts Receivable'),
	// 	report_name: 'Accounts Receivable',
	// 	custom_filters: [
	// 		{
	// 			fieldname: 'report_date',
	// 			fieldtype: 'Date',
	// 			label: __("Posting Date"),
	// 			default: frappe.datetime.get_today(),
	// 		},
	// 	]
	// },
	{
		value: 'account_summary',
		label: __('Account Summary'),
		report_name: 'Account Summary',
		custom_filters: [
			{
				fieldname: 'order_by',
				fieldtype: 'Select',
				label: __('Order By'),
				default: "Income DESC",
				options: [
					{ value: "", label: __('') },
					{ value: "Income DESC", label: __('Income DESC') },
					{ value: "Cost DESC", label: __('Cost DESC') },
					{ value: "Cost ASC", label: __('Cost ASC') },
					{ value: "Expense DESC", label: __('Expense DESC') },
					{ value: "Profit DESC", label: __('Profit DESC') },
					{ value: "Profit ASC", label: __('Profit ASC') },
					{ value: "Cash Flow DESC", label: __('Cash Flow DESC') },
					{ value: "Cash Flow ASC", label: __('Cash Flow ASC') },
					{ value: "Sales Order", label: __('Sales Order') },
				],
			},
			{
				fieldname: 'display_number',
				fieldtype: 'Select',
				label: __('Display Quantity'),
				default: 10,
				options: [
					{ value: 10, label: 10 },
					{ value: 15, label: 15 },
					{ value: 20, label: 20 },
				],
			},
			{
				fieldname: 'ignore_internal_customer',
				fieldtype: 'Check',
				label: __("Ignore Internal Customer"),
				default: 1,
			},
		]
	},
	{
		value: 'cash_flow',
		label: __('Cash Flow'),
		report_name: 'Cash Flow Statement',
		custom_filters: [
			{
				fieldname: 'account',
				fieldtype: 'Link',
				label: __('Account'),
				options: "Account"
			},
			{
				"fieldname": "cash_flow_category",
				"label": __("Cash Flow Category"),
				"options": "Cash Flow Category",
				"fieldtype": "Link",
				"filters":{
					// "is_group":0
				}
			},
			{
				fieldname: 'charts_based_on',
				fieldtype: 'Select',
				label: "图表汇总方式",
				default: "category",
				options: [
					{ value: "", label: "" },
					{ value: "category", label: __('Category') },
					{ value: "parent_category", label: __('Parent Category') },
					{ value: "company", label: __('Company') },
					{ value: "account", label: __('Account') },
					{ value: "statistical_stage", label: __('Statistical Stage') },
					{ value: "business_division", label: __('Business Division') },
					{ value: "project", label: __('Project') },
					{ value: "party", label: __('Party')},
				],
			},
			{
				fieldname: 'ignore_debit_and_credit',
				fieldtype: 'Check',
				label: __("Ignore") + __("Debit") + __("and") + __("Credit"),
				default: 0,
			},
		]
	},
	{
		value: 'expenses',
		label: __('Expenses'),
		report_name: 'Expenses By Business Division',
		custom_filters: [
			{
				fieldname: 'group_by',
				fieldtype: 'Select',
				label: __('Group By'),
				options: [
					{ value: "Parent Account", label: __("Parent Account") },
					{ value: "Account", label: __("Account") },
				],
				default: "Parent Account",
			},
			{
				fieldname: 'chart_type',
				fieldtype: 'Select',
				label: __("Chart Type"),
				default: "Timeline",
				options: [
					{ value: "", label: "" },
					{ value: "Timeline", label: __('Timeline') },
					{ value: "Main Project", label: __('Main Project') },
					{ value: "Project", label: __('Project') },
					{ value: "Company", label: __('Company') },
				],
			},
		]
	},
	{
		value: 'marketing_expenses',
		label: __('Marketing Expenses'),
		report_name: 'Marketing Expenses',
		custom_filters: [
			{
				fieldname: 'project',
				fieldtype: 'Select',
				label: __('Project'),
				options: [
					{ value: "天津市场开拓", label: __("天津市场开拓") },
					{ value: "深圳市场开拓", label: __("深圳市场开拓") },
				],
				default: "天津市场开拓",
			},
		]
	},
	{
		value: 'stock_balance_analysis',
		label: __('Stock Balance Analysis'),
		report_name: 'Stock Balance Analysis',
		custom_filters: [
			{
				"fieldname": "warehouse",
				"label": __("Warehouse"),
				"fieldtype": "Link",
				"options": "Warehouse",
			},
			{
				"fieldname": "item_code",
				"label": __("Item"),
				"fieldtype": "Link",
				"options": "Item",
			},
			{
				"fieldname": "group_by",
				"label": __("Group By"),
				"fieldtype": "Select",
				"options": [
					{value: 'project', label: __('Project')},
					{value: 'warehouse', label: __('Warehouse')},
					{value: 'item_code', label: __('Item')},
				],
			},
			{
				"fieldname": "charts_based_on",
				"label": __("Charts Based On"),
				"fieldtype": "Select",
				"reqd": 1,
				"options": [
					{value: 'project', label: __('Project')},
					{value: 'warehouse', label: __('Warehouse')},
					{value: 'item_code', label: __('Item')},
				],
				"default": "project",
			},
		]
	},
]

function set_chart_option(chart_options, activeName, chartRefs) {
	let tab_name = tabs[activeName.value].value
	if (!chartRefs[tab_name] || chart_options.data.datasets.length == 0) return
	const myChart = echarts.init(chartRefs[tab_name], 'light');
	let echartsOption = {

	}
	let dataZoomConfig = [
		{
			type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
			xAxisIndex: 0, // 设置它控制 x 轴，默认控制第一个 xAxis
			start: 0, // 左边在 10% 的位置
			end: 100 // 右边在 60% 的位置
		},
		{
			type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
			xAxisIndex: 0, // 设置它控制 x 轴，默认控制第一个 xAxis
			start: 0, // 左边在 10% 的位置
			end: 100 // 右边在 60% 的位置
		}
	]
	let xAxisConfig = {
		// axisTick: {
		// 	alignWithLabel: true
		// },
		type: 'category',
		data: chart_options.data.labels,
		axisLabel: {
			rotate: 45, // 设置标签旋转 45 度
			interval: 0, // 设置为 0 强制显示所有标签
			showMinLabel: true, // 设置为 true 显示最小值标签
			showMaxLabel: true, // 设置为 true 显示最大值标签
			formatter: function (value, index) {
				// 假设我们希望每个标签最多显示 10 个字符
				var maxLength = 8;
				if (value.length > maxLength) {
					return value.slice(0, maxLength) + '...'; // 截断并添加省略号
				}
				return value; // 标签长度合适，直接返回
			}
		}
	}

	if (chart_options.type == 'donut') {
		echartsOption.tooltip = {
			trigger: 'item'
		}
		echartsOption.legend = {
			top: '5%',
			left: 'center'
		}
		let data = chart_options.data.labels.map((item, index) => {
			return {
				name: t(item),
				value: chart_options.data.datasets[0].values[index]
			}
		})
		echartsOption.series = [
			{
				type: 'pie',
				radius: ['40%', '70%'],
				avoidLabelOverlap: false,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
				},
				label: {
					show: false,
					position: 'center'
				},
				emphasis: {
					label: {
						show: true,
						fontSize: 40,
						fontWeight: 'bold'
					}
				},
				labelLine: {
					show: false
				},
				data: data
			}
		]

	} else if (chart_options.type == 'percentage') {
		echartsOption.tooltip = {
			trigger: 'item'
		}
		echartsOption.legend = {
			top: '5%',
			left: 'center'
		}
		let data = chart_options.data.labels.map((item, index) => {
			let sum = 0
			chart_options.data.datasets.forEach(item => {
				sum += item.values[index]
			})
			return {
				name: t(item),
				value: sum
			}
		})
		echartsOption.series = [
			{
				type: 'pie',
				radius: ['40%', '70%'],
				avoidLabelOverlap: false,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
				},
				label: {
					show: false,
					position: 'center'
				},
				emphasis: {
					label: {
						show: true,
						fontSize: 40,
						fontWeight: 'bold'
					}
				},
				labelLine: {
					show: false
				},
				data: data
			}
		]

	} else {
		// 其他默认为line，bar
		echartsOption.tooltip = {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				animation: false,
				label: {
					backgroundColor: '#ccc',
					borderColor: '#aaa',
					borderWidth: 1,
					shadowBlur: 0,
					shadowOffsetX: 0,
					shadowOffsetY: 0,
					color: '#222'
				}
			},
			formatter: function (params) {
				let res = ``
				params.forEach((item, index) => {
					res += `
					<div class="flex justify-content-between" style="color:${item.color};min-width:200px;">
						<div class="bold">${item.seriesName} :</div>
						<div>${formatCurrency(item.value)}</div>
					</div>`;
				});
				return res;
			}
		}

		echartsOption.toolbox = {
			show: true,
			feature: {
				dataView: { show: true, readOnly: false },
				magicType: { show: true, type: ['line', 'bar', 'stack'] },
				restore: { show: true },
				// saveAsImage: { show: true }
				myToggleMarkLine: {
					show: true,
					title: '显示标记',
					icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891', // 你可以使用自定义的图标路径
					onclick: function () {
						var chart = myChart;
						var series = chart.getOption().series;
						series.forEach(function (item) {
							item.markLine.lineStyle.opacity = item.markLine.lineStyle.opacity?0:1;
							item.markPoint.itemStyle.opacity = item.markPoint.itemStyle.opacity?0:1;
						});
						chart.setOption({ series: series });
					}
				},
			}
		}

		echartsOption.xAxis = {
			type: 'category',
			data: chart_options.data.labels,
			axisLabel: {
				rotate: 45, // 设置标签旋转 45 度
				interval: 0, // 设置为 0 强制显示所有标签
				showMinLabel: true, // 设置为 true 显示最小值标签
				showMaxLabel: true, // 设置为 true 显示最大值标签
				formatter: function (value, index) {
					// 假设我们希望每个标签最多显示 10 个字符
					var maxLength = 8;
					if (value.length > maxLength) {
						return value.slice(0, maxLength) + '...'; // 截断并添加省略号
					}
					return value; // 标签长度合适，直接返回
				}
			}
		}

		echartsOption.yAxis = {
			type: 'value'
		}

		let chart_series = chart_options.data.datasets.map((item) => {
			let chart_type = item.chartType || chart_options.type
			let markLineConfig = {
				lineStyle:{
					opacity:0,
				},
				data: [{ type: 'average', name: t('Average') }]
			}
			// 绘制图表
			const labelOption = {
				show: true,
				rotate: 90,
				formatter: function (params) {
					// 如果数据的值为 0，则返回空字符串，否则返回原始值
					return params.value === 0 ? '' : params.value.toLocaleString('zh-CN',
						{
							minimumFractionDigits: 2, // 设置小数点后至少显示的位数
							maximumFractionDigits: 2  // 设置小数点后最多显示的位数
						}
					);
				},
				fontSize: 12,
				color: '#FFFFFF',
				rich: {
					name: {}
				}
			};
			var emphasisStyle = {
				itemStyle: {
					shadowBlur: 10,
					shadowColor: 'rgba(0,0,0,0.3)',
				},
				focus: 'series'
			};

			return {
				name: item.name,
				type: chart_type,
				smooth: true,
				barGap: 0,
				label: item.values.length < 10 ? labelOption : {},// 柱状图标签，数据多时效果不好
				data: item.values,
				showBackground: true,
				emphasis: emphasisStyle,
				markPoint: {
					itemStyle:{
						opacity:0,
					},
					data: [
						{ type: 'max', name: t('Maximum') },
						{ type: 'min', name: t('Minimum') }
					]
				},
				markLine: markLineConfig,
				animationDelay: function (idx) {
					return idx * 10;
				},
				animationEasing: 'elasticOut',
				animationDelayUpdate: function (idx) {
					return idx * 5;
				},
				backgroundStyle: {
					color: 'rgba(220, 220, 220, 0.2)'
				}
			}
		})

		echartsOption.series = chart_series
		echartsOption.legend = {
			orient: 'vertical',
			right: 10,
			top: 'center'
		}
		echartsOption.dataZoom = [
			{
				type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
				xAxisIndex: 0, // 设置它控制 x 轴，默认控制第一个 xAxis
				start: 0, // 左边在 10% 的位置
				end: 100 // 右边在 60% 的位置
			},
			{
				type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
				xAxisIndex: 0, // 设置它控制 x 轴，默认控制第一个 xAxis
				start: 0, // 左边在 10% 的位置
				end: 100 // 右边在 60% 的位置
			}
		]
	}

	echartsOption && myChart.setOption(echartsOption);
	window.addEventListener('resize', function () {
		myChart.resize();
	});
}

function set_report_chart(chart_options,element) {
	let myChart
	let exist_chart =  echarts.getInstanceByDom(element)
	if (exist_chart) {
		exist_chart.clear()
		myChart = exist_chart
	} else {
		myChart = echarts.init(element, 'light');
	}
	
	let echartsOption = {}

	if (chart_options.type == 'donut') {
		echartsOption.tooltip = {
			trigger: 'item'
		}
		echartsOption.legend = {
			top: '5%',
			left: 'center'
		}
		let data = chart_options.data.labels.map((item, index) => {
			return {
				name: t(item),
				value: chart_options.data.datasets[0].values[index]
			}
		})
		echartsOption.series = [
			{
				type: 'pie',
				radius: ['40%', '70%'],
				avoidLabelOverlap: false,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
				},
				label: {
					show: false,
					position: 'center'
				},
				emphasis: {
					label: {
						show: true,
						fontSize: 40,
						fontWeight: 'bold'
					}
				},
				labelLine: {
					show: false
				},
				data: data
			}
		]

	} else if (chart_options.type == 'percentage') {
		echartsOption.tooltip = {
			trigger: 'item'
		}
		echartsOption.legend = {
			top: '5%',
			left: 'center'
		}
		let data = chart_options.data.labels.map((item, index) => {
			let sum = 0
			chart_options.data.datasets.forEach(item => {
				sum += item.values[index]
			})
			return {
				name: t(item),
				value: sum
			}
		})
		echartsOption.series = [
			{
				type: 'pie',
				radius: ['40%', '70%'],
				avoidLabelOverlap: false,
				itemStyle: {
					borderRadius: 10,
					borderColor: '#fff',
					borderWidth: 2
				},
				label: {
					show: false,
					position: 'center'
				},
				emphasis: {
					label: {
						show: true,
						fontSize: 40,
						fontWeight: 'bold'
					}
				},
				labelLine: {
					show: false
				},
				data: data
			}
		]

	} else {
		// 其他默认为line，bar
		echartsOption.tooltip = {
			trigger: 'axis',
			axisPointer: {
				type: 'cross',
				animation: false,
				label: {
					backgroundColor: '#ccc',
					borderColor: '#aaa',
					borderWidth: 1,
					shadowBlur: 0,
					shadowOffsetX: 0,
					shadowOffsetY: 0,
					color: '#222'
				}
			},
			formatter: function (params) {
				let res = ``
				params.forEach((item, index) => {
					res += `
					<div class="flex justify-content-between" style="color:${item.color};min-width:200px;">
						<div class="bold">${item.seriesName} :</div>
						<div>${formatCurrency(item.value)}</div>
					</div>`;
				});
				return res;
			}
		}

		echartsOption.toolbox = {
			show: true,
			feature: {
				dataView: { show: true, readOnly: false },
				magicType: { show: true, type: ['line', 'bar', 'stack'] },
				restore: { show: true },
				// saveAsImage: { show: true }
				myToggleMarkLine: {
					show: true,
					title: '显示标记',
					icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891', // 你可以使用自定义的图标路径
					onclick: function () {
						var chart = myChart;
						var series = chart.getOption().series;
						series.forEach(function (item) {
							item.markLine.lineStyle.opacity = item.markLine.lineStyle.opacity?0:1;
							item.markPoint.itemStyle.opacity = item.markPoint.itemStyle.opacity?0:1;
						});
						chart.setOption({ series: series });
					}
				},
			}
		}

		echartsOption.xAxis = {
			type: 'category',
			data: chart_options.data.labels,
			axisLabel: {
				rotate: 45, // 设置标签旋转 45 度
				interval: 0, // 设置为 0 强制显示所有标签
				showMinLabel: true, // 设置为 true 显示最小值标签
				showMaxLabel: true, // 设置为 true 显示最大值标签
				formatter: function (value, index) {
					// 假设我们希望每个标签最多显示 10 个字符
					var maxLength = 8;
					if (value.length > maxLength) {
						return value.slice(0, maxLength) + '...'; // 截断并添加省略号
					}
					return value; // 标签长度合适，直接返回
				}
			}
		}

		echartsOption.yAxis = {
			type: 'value'
		}

		let chart_series = chart_options.data.datasets.map((item) => {
			let chart_type = item.chartType || chart_options.type
			let markLineConfig = {
				lineStyle:{
					opacity:0,
				},
				data: [{ type: 'average', name: t('Average') }]
			}
			// 绘制图表
			const labelOption = {
				show: true,
				rotate: 90,
				formatter: function (params) {
					// 如果数据的值为 0，则返回空字符串，否则返回原始值
					return params.value === 0 ? '' : params.value.toLocaleString('zh-CN',
						{
							minimumFractionDigits: 2, // 设置小数点后至少显示的位数
							maximumFractionDigits: 2  // 设置小数点后最多显示的位数
						}
					);
				},
				fontSize: 12,
				color: '#FFFFFF',
				rich: {
					name: {}
				}
			};
			var emphasisStyle = {
				itemStyle: {
					shadowBlur: 10,
					shadowColor: 'rgba(0,0,0,0.3)',
				},
				focus: 'series'
			};

			return {
				name: item.name,
				type: chart_type,
				smooth: true,
				barGap: 0,
				label: item.values.length < 10 ? labelOption : {},// 柱状图标签，数据多时效果不好
				data: item.values,
				showBackground: true,
				emphasis: emphasisStyle,
				markPoint: {
					itemStyle:{
						opacity:0,
					},
					data: [
						{ type: 'max', name: t('Maximum') },
						{ type: 'min', name: t('Minimum') }
					]
				},
				markLine: markLineConfig,
				animationDelay: function (idx) {
					return idx * 10;
				},
				animationEasing: 'elasticOut',
				animationDelayUpdate: function (idx) {
					return idx * 5;
				},
				backgroundStyle: {
					color: 'rgba(220, 220, 220, 0.2)'
				}
			}
		})

		echartsOption.series = chart_series
		echartsOption.legend = {
			orient: 'vertical',
			right: 10,
			top: 'center'
		}
		echartsOption.dataZoom = [
			{
				type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
				xAxisIndex: 0, // 设置它控制 x 轴，默认控制第一个 xAxis
				start: 0, // 左边在 10% 的位置
				end: 100 // 右边在 60% 的位置
			},
			{
				type: 'inside', // 这个 dataZoom 组件是 inside 型 dataZoom 组件
				xAxisIndex: 0, // 设置它控制 x 轴，默认控制第一个 xAxis
				start: 0, // 左边在 10% 的位置
				end: 100 // 右边在 60% 的位置
			}
		]
	}
	echartsOption && myChart.setOption(echartsOption);
	window.addEventListener('resize', function () {
		myChart.resize();
	});
}

const get_span = (report_summary) => {

	let smmary_len = report_summary.length
	if (smmary_len <= 4 && smmary_len > 0) {
		return 24 / smmary_len
	} else {
		return 4
	}

}
const getTooltipContent=(item) => {
	return item.label; // 或者其他您想返回的内容
}

const getSummary = (param,report_columns) => {
	const { columns, data } = param;
	const sums = [];
	columns.forEach((column, index) => {
		if (index === 1) {
			sums[index] = t('Total');
			return;
		}
		// 获取当前列的配置信息
		const colConfig = report_columns?.find(col => col.fieldname === column.property);
		if (colConfig && colConfig.fieldtype === 'Currency') {
			const values = data.map(item => {
				const value = item[column.property];
				const parsedValue = parseFloat(value); // 尝试将值转换为数字
				return Number.isNaN(parsedValue) ? 0 : parsedValue; // 如果转换后的值是NaN，则返回0，否则返回转换后的值
			});
			const sum = values.reduce((acc, val) => acc + val, 0);
			sums[index] = formatCurrency(sum); // 格式化货币值
		} else {
			sums[index] = '';
		}
	});
	return sums;
}
export {
	menu_list,
	business_division_options,
	tabs, 
	status_color_map,
	shortcuts,
	formatCurrency,
	getFormatter,
	t,
	disabledDate,
	set_chart_option,
	guessClass,
	// get_report_data,
	get_span,
	getTooltipContent,
	set_report_chart,
	getSummary,
	get_url
}