<template>
	<div v-for="(outerItem, outerIndex) in data.undone_list_summary" :key="outerIndex">
		<div style="margin-top:1rem;font-weight:bold;">
			{{ t(outerIndex) }}
		</div>
		<div class="doctype workflow_section">
			<div v-for="(item, innerIndex) in outerItem" :key="innerIndex">
			<el-card shadow="hover" class="mx-2 my-2" style="width: 360px;" :style="workflow_style[outerIndex]"
				@click="show_details(item, innerIndex, $event)">
				<template #header class="workflow_doctype">
					<div class="head_footer">
						<div>
							<a :href="item.url" target="_blank">{{ item.name }}</a>
						</div>
						<div>
							<span class="indicator-pill whitespace-nowrap" :class="btn_class_map[item.workflow_state]">
								<span>{{ t(item.workflow_state) }}</span>
							</span>
						</div>
					</div>
					<div>
						<div class="status">{{ item.status }}</div>
					</div>
				</template>

				<div class="workflow_amount">
					{{ item.grand_total.toLocaleString('zh-CN', { style: 'currency', currency: 'CNY' }) }}
				</div>

				<template #footer>
					<div class="head_footer">
						<div>
							记账日：<span style="color:black">{{ item.posting_date }}</span>
						</div>
						<div>
							最后更新：<span style="color:lightcoral">{{ item.modified }}</span>
						</div>
					</div>
				</template>
			</el-card>
			</div>
		</div>



	</div>


	<div v-for="(doctype, index) in request_doctypes" :key="index">
		<div v-if="request_docs[doctype]">
			<div style="margin-top:1rem;font-weight:bold;">
				{{ t(doctype) }}
			</div>
			<div class="doctype workflow_section">
				<div v-for="(doc, doc_index) in request_docs[doctype]" :key="doc_index">
					<el-card v-if="!handled_doc.includes(doc.name)" shadow="hover" class="mx-2 my-2"
						style="width: 360px;" :style="workflow_style[doctype]">
						<!-- <pre>{{ doc }} {{doctype}}</pre> -->
						<template #header class="workflow_doctype">
							<div class="head_footer">
								<div>
									<a :href="get_doc_link(doctype, doc.name)" target="_blank">{{ doc.name }}</a>
								</div>
								<div>
									<span class="indicator-pill whitespace-nowrap orange">
										<span>{{ t(doc.status) || t('Draft') }}</span>
									</span>
								</div>
							</div>
						</template>

						<div class="" >
							<div v-for="(value, key) in doc" :key="key">
								<p v-if="!['name'].includes(key)" class="flex">
								<div class="text-secondary w-25">{{ t(request_labels[doctype][key]) }}:</div>
								<div>{{ t(value) }}</div>
								</p>
							</div>
						</div>

						<template #footer>
							<div class="head_footer">
								<el-button type="danger" size="small">{{ t('Delete') }}</el-button>
								<el-button type="primary" size="small" @click="submit_doc(doctype, doc, $event)">{{
									t('Submit') }}</el-button>
							</div>
						</template>
					</el-card>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import {
	t,
} from '../base/vue_utils.js'

const isVisible = ref(false)
const data = reactive({})
// const undone_list_summary = reactive([])
// const workflows = reactive([])
// const expense_claim = reactive([])
// const material_request = reactive([])
const workflow_style = {
	'Sales Order': "background-color: rgb(239.8, 248.9, 235.3)",
	'Purchase Order': "background-color: rgb(252.5, 245.7, 235.5)",
	'Expense Claim': "background-color: rgb(235.9, 245.3, 255)",
	'Payment Application': "background-color: rgb(254, 240.3, 240.3)",
	'Material Request': "background-color: #F2F6FC",
	'Payment Request': "background-color: #FAFCFF",
	'Employee Advance': "background-color: #FAFAFA",
	'Checkin Patch': "background-color: rgb(239.8, 248.9, 235.3)",
	'Travel Request': "background-color: rgb(252.5, 245.7, 235.5)",
	'Attendance Request': "background-color: rgb(235.9, 245.3, 255)",
	'Leave Application': "background-color: rgb(254, 240.3, 240.3)",
}
const show_details = (item, innerIndex, $event) => {
	const currentTarget = $event.currentTarget;
	frappe.show_uwl_dialog(item, currentTarget)
}

const btn_class_map = {
	'创建': 'pink',
	'提报': 'orange',
	'部长已审批': 'cyan',
	'总经理已审批': 'blue',
	'取消': 'yellow',
	'拒绝': 'red',
	'审批通过': 'green'
}

const request_doctypes = [
	'Checkin Patch',
	'Travel Request',
	'Attendance Request',
	'Leave Application',
]

const request_docs = reactive({})
const request_labels = reactive({})
const inlist_fields = [
	'remark', 'status',
	'remarks', 'project',
	'company', 'party',
	'posting_date', 'transaction_date',
	'docstatus', 'name',
	'creation', 'modified','description','employee_name'
]
const get_doc_link = (doctype, docname) => {
	return `/app/${frappe.router.slug(doctype)}/${encodeURIComponent(docname)}`
}
const get_request_docs = (doctype_list) => {
	if (!doctype_list) {
		doctype_list = request_doctypes
	}
	doctype_list.forEach(doctype => {
		frappe.model.with_doctype(doctype, function () {
			let meta = frappe.get_meta(doctype);
			let fields = ['name']
			let labels = {}
			meta.fields.forEach((value) => {
				if (!["Section Break", "Column Break"].includes(value.fieldtype) && (value.in_list_view == 1 || in_list(inlist_fields, value.fieldname))) {
					fields.push(value.fieldname);
					labels[value.fieldname] = value.label;
				}
			});
			request_labels[doctype] = labels
			frappe.db.get_list(doctype,
				{
					filters: {
						docstatus: 0,
					},
					fields: fields
				}
			).then((r) => {
				if (r.length > 0) {
					request_docs[doctype] = r
				}
			})
		})
	})
}
const handled_doc = reactive([])
const submit_doc = (doctype, doc, $event) => {
	ElMessageBox.confirm(
		t("Confirm") + t('Submit') + doc.name + '?',
		t('Warning'),
		{
			confirmButtonText: t('Submit'),
			cancelButtonText: t('Cancel'),
			type: 'warning',
			draggable: true,
			overflow: true,
		}
	)
		.then(() => {
			frappe.db.get_doc(doctype,doc.name).then((doc)=>{
				frappe.call({
				method: "frappe.client.submit",
				args: {
					doc: doc,
				},
				callback: function (r) {
					handled_doc.push(doc.name)
					ElMessage({
						type: 'success',
						message: t('Actions') + t('Completed'),
					})
				},
			});
			})
			
			
		})
		.catch(() => {
			ElMessage({
				type: 'info',
				message: t('Actions') + t('Is Cancelled'),
			})
		})
}
const get_data = () => {
	frappe.call({
		method: "localization.localization.utils.uwl.get_workflow_data",
		args: { user: frappe.session.user },
		callback: (r) => {
			if (frappe.boot.developer_mode || frappe.user.has_role('System Manager')) {
				console.log(r.message)
			}

			if (r.message) {
				isVisible.value = true
				let res = r.message
				data.undone_list_summary = res.undone_list_summary
				data.workflows = res.workflows
			} else {
				el.innerHTML = `<div>当前无待审批单据</div>`
				el.style.display = 'block'
			}
		}
	})
}
onMounted(() => {
	get_data()
	get_request_docs()
});



</script>

<style scoped></style>