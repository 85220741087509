frappe.gantt = function (el,project) {
	let sb = document.querySelector('.layout-side-section')
	gantt.clearAll();
	if(!project) {
		el.style.display = 'none'
		sb.style.display = 'block'
		return
	};
	
	frappe.call({
		method:"localization.localization.overrides.task.get_task_list_for_gantt",
		args:{
			project:project
		},
		callback:(r)=>{
			if(r.message){
				
				sb.style.display = 'none'
				el.style.display = 'block'
				let data = r.message.data
				let holidays = r.message.holidays
				data.forEach(function (task) {
					task.start_date = new Date(task.start_date)
					let end_date = new Date(task.end_date);
					end_date.setHours(23, 59, 59, 999); // 设置小时、分钟、秒和毫秒
					task.end_date = end_date;
					task.progress = task.progress / 100
				})
				let tasks = {
					data: data,
					links: r.message.links
				};
				let data_count = data.length
				el.style.height = ((data_count + 1) * 40) + 'px'
				if (el) {
					gantt.templates.timeline_cell_class = function(task,date){
						let date_str = frappe.datetime.get_datetime_as_string(date).split(' ')[0]
						let today = frappe.datetime.get_today()
						if(date_str == today) {
							return "today"
						}
						if(in_list(holidays,date_str) && in_list(holidays,date_str)){ 
							return "week-end" ;
						}
					};
					gantt.config.columns = [
						{name:"text",       label:__("Name"),  width:"300", tree:true},
						{name:"start_date", label:__("Expected Start Date"),align:"right",
							template: function (task) {
								let date_str = frappe.datetime.get_datetime_as_string(task.start_date).split(' ')[0]
								const [, month, day] = date_str.split('-');
								return `${month}-${day}`
							}
						},
						{name:"duration",   label:__("Duration (Days)"),   align:"center" },
						{name:"status",   label:__("Status"),   align:"center" },
					];

					gantt.templates.task_text=function(start,end,task){
						return task.text + "(" +flt(task.progress * 100,1)+"%" + ")";
					};
					gantt.init(el); // 初始化 gantt 图表
					gantt.i18n.setLocale("cn");
					gantt.parse(tasks); // 解析并加载任务数据
					gantt.config.scales = [
						{unit: "month", step: 1, format: "%F, %Y"},
						// {unit: "week", step: 1, format: function(date){
						// return "第"+getWeekOfMonthNumber(date)+__("Week");
						// }},
						{unit: "day", step:1, format: "%d", css: function(date) { 
							
							let date_str = frappe.datetime.get_datetime_as_string(date).split(' ')[0]
							let today = frappe.datetime.get_today()
							if(date_str == today) {
								return "today"
							}
							if(in_list(holidays,date_str)){ 
								return "week-end"; 
							} 
						}} 
					];
					// 增加对导出的插件支持
					gantt.plugins({
						export_api: true
					});

			
					tasks.data.forEach(function (task) {
						if (task.parent === 0) {
							gantt.open(task.id);
							// 父任务不可编辑
							gantt.getTask(task.id).readonly = true;
						}
					});
					// 允许通过拖拽来移动任务
					gantt.config.drag_move = true;
					// gantt.config({allowTaskMove: true,})
			
					// gantt.attachEvent("onBeforeTaskDrag", function(id, mode) {
					// 	console.log("onBeforeTaskDrag")
					// 	let task = gantt.getTask(id);
					// 	console.log(task)

					// });
			
					// gantt.attachEvent("onTaskDrag", function (id, mode, e) {
					// 	// console.log("Task is being dragged: " + id, mode, e);
					// });
					gantt.attachEvent("onAfterTaskDrag", function (id, mode, e) {
						date_format= "YYYY-MM-DD"
						let task = gantt.getTask(id);
						
						if (task.owner == frappe.session.user || task.assign_to || frappe.user.has_role('Projects User')) {
							frappe.db.set_value('Task', task.id, {
								'exp_start_date': moment(task.start_date).format(date_format),
								'exp_end_date': moment(task.end_date).subtract(1, 'seconds').format(date_format),
								'progress': task.progress * 100
							}).then((r)=>{
								console.log(r)
							}).catch((error) => {
								frappe.db.get_doc('Task',task.id).then(doc=>{
									let original_end_date = new Date(doc.exp_end_date)
									original_end_date.setHours(23, 59, 59, 999)
									task.start_date = new Date(doc.exp_start_date) 
									task.end_date =  original_end_date
									gantt.updateTask(doc.name)
									frappe.msgprint('任务修改失败，日期已重置。')
								})
							});
							let message = `
							<div>
								<p>任务 <span style="color:blue;font-weight:bold;">${task.id}</span> 已修改</p>
								<p>开始日期：${frappe.datetime.get_datetime_as_string(task.start_date)}</p>
								<p>结束日期：${frappe.datetime.get_datetime_as_string(task.end_date)}</p>
								<p>进度：${flt(task.progress* 100,1)}%</p>
							</div>
							`
							frappe.show_alert({ message: message, indicator: "green" });
							updateParentProgress(task.id)
						} else {
							frappe.msgprint('公开任务只有创建人和已分配的用户才能修改,如有需要,请联系<span style="color:blue;font-weight:bold;">项目管理员</span>分派任务')
						}
						
					});
				}
			} else {
				el.style.display = 'none'
			}
		}
	})

	
}

function getWeekOfMonthNumber(date){
    let adjustedDate = date.getDate()+date.getDay();
    let prefixes = ['0', '1', '2', '3', '4', '5'];
    return (parseInt(prefixes[0 | adjustedDate / 7])+1);
} 

function updateParentProgress(id) {
	let task = gantt.getTask(id);
	let parent = gantt.getTask(task.parent);
	if(!parent) return
	let childs = gantt.getChildren(task.parent)
	if(!childs.length) return
	let progress = 0
	childs.forEach(function (task) {
		progress += gantt.getTask(task).progress
	})
	parent.progress = progress / childs.length

	gantt.updateTask(task.parent)
}