frappe.ui.form.Attachments = class Attachments extends frappe.ui.form.Attachments {
	attachment_uploaded(attachment) {
		this.dialog && this.dialog.hide();
		this.update_attachment(attachment);
		this.frm.sidebar.reload_docinfo();
		if(this.frm.doc.doctype == 'Invoice Recognition Group') {
			this.frm.dirty()
			frappe.sync_items_with_attachments(this.frm)
		}
		if(this.frm.doc.doctype == 'E Invoice') {
			this.frm.refresh()
			this.frm.dirty()
			this.frm.enable_save()
		}
		

		if (this.fieldname) {
			this.frm.set_value(this.fieldname, attachment.file_url);
		}
	}

	remove_attachment(fileid, callback) {
		if (!fileid) {
			if (callback) callback();
			return;
		}

		var me = this;
		return frappe.call({
			method: "frappe.desk.form.utils.remove_attach",
			type: "DELETE",
			args: {
				fid: fileid,
				dt: me.frm.doctype,
				dn: me.frm.docname,
			},
			callback: function (r, rt) {
				if (r.exc) {
					if (!r._server_messages) frappe.msgprint(__("There were errors"));
					return;
				}
				me.remove_fileid(fileid);
				me.frm.sidebar.reload_docinfo();
				if (callback) callback();
				if(me.frm.doc.doctype == 'Invoice Recognition Group') {
					me.frm.dirty()
					frappe.sync_items_with_attachments(me.frm)
				}
				if(me.frm.doc.doctype == 'E Invoice') {
					me.frm.refresh()
					me.frm.dirty()
					me.frm.enable_save()
				}
			},
		});
	}
}