
import DataTable from "frappe-datatable";

// Expose DataTable globally to allow customizations.
window.DataTable = DataTable;
frappe.utils.build_table = function (element, report_result,formatter,layout) {

	let columns = report_result.columns;
	let data = report_result.result;
	let add_total_row = report_result.add_total_row;
	columns = prepare_columns(columns,formatter)
	data = prepare_data(data, columns)
	this.datatable = render_datatable(element, data, columns, add_total_row,layout)

	return this.datatable;
}

// const formatter = function(value, row, column, data, default_formatter) {
// 	value = default_formatter(value, row, column, data);
// 	if (column.id == 'balance' && data) {
// 		value = `<span style="color: Orange">` + value.bold() +`</span>`;
// 	};
// 	return value
// };

function prepare_columns(columns,formatter) {
	return columns.map((column) => {
		column = frappe.report_utils.prepare_field_from_column(column);

		const format_cell = (value, row, column, data) => {
			if (column.isHeader && !data && this.data) {
				let index = 1;

				if (column.colIndex === index && !value) {
					value = "Total";
					column = { fieldtype: "Data" }; // avoid type issues for value if Date column
				} else if (["Currency", "Float"].includes(column.fieldtype)) {
					// proxy for currency and float
					data = this.data[0];
				}
			}
			return frappe.format(
				value,
				column,
				{ for_print: false, always_show_decimals: true },
				data
			);
		};

		let compareFn = null;
		if (column.fieldtype === "Date") {
			compareFn = (cell, keyword) => {
				if (!cell.content) return null;
				if (keyword.length !== "YYYY-MM-DD".length) return null;

				const keywordValue = frappe.datetime.user_to_obj(keyword);
				const cellValue = frappe.datetime.str_to_obj(cell.content);
				return [+cellValue, +keywordValue];
			};
		}

		return Object.assign(column, {
			id: column.fieldname,
			// The column label should have already been translated in the
			// backend. Translating it again would cause unexpected behaviour.
			name: column.label,
			width: parseInt(column.width) || null,
			editable: column.editable ?? false,
			compareValue: compareFn,
			format: (value, row, column, data, filter) => {
				if(formatter) {
					return formatter(
						value,
						row,
						column,
						data,
						format_cell,
						filter
					);
				}
				return format_cell(value, row, column, data);
			},
		});
	});
}



function prepare_data(data, columns) {
	return data.map((row) => {
		let row_obj = {};
		if (Array.isArray(row)) {
			columns.forEach((column, i) => {
				row_obj[column.id] = row[i];
			});

			return row_obj;
		}
		return row;
	});
}
function render_datatable(el, data, columns, add_total_row,layout) {
	if (add_total_row) {
		data = data.slice();
		data.splice(-1, 1);
	}

	const get_translations = function () {
		let translations = {};
		translations['zh'] = {
			"Sort Ascending": __("Sort Ascending"),
			"Sort Descending": __("Sort Descending"),
			"Reset sorting": __("Reset sorting"),
			"Remove column": __("Remove column"),
			"No Data": __("No Data"),
			"{count} cells copied": {
				1: __("{count} cell copied"),
				default: __("{count} cells copied"),
			},
			"{count} rows selected": {
				1: __("{count} row selected"),
				default: __("{count} rows selected"),
			},
		};
	
		return translations;
	};

	let datatable_options = {
		columns: columns,
		data: data,
		inlineFilters: true,
		language: frappe.boot.lang,
		translations: get_translations(),
		layout: layout? layout:"fixed",
		cellHeight: 33,
		showTotalRow: true,
		direction: frappe.utils.is_rtl() ? "rtl" : "ltr",
		hooks: {
			columnTotal: frappe.utils.report_column_total,
		}
	}

	return new window.DataTable(el, datatable_options)
}