<template>
	<div class="border rounded">

		<div style="display: flex;
			width: 100%;
			padding: 0;
			flex-wrap: wrap;
			margin-top: 16px;
			margin-bottom: 16px;
			align-items: center;
			justify-content: flex-start;" class="flex align-items-center justify-content-start   my-2 w-100">
			<div class="mx-2 my-2" v-for="filter in filters">
				<el-date-picker v-model="report_filters[filter.fieldname]" class="block mx-2 my-2"
					style="max-width: 160px;" type="date" @change="handleCustFilterChange(filter, $event)"
					v-if="filter.fieldtype == 'Date'" :placeholder="custom_filters[filter.label]" 
					:disabled-date="disabledDate" format="YYYY-MM-DD"
					:shortcuts="shortcuts" />

				<el-select v-model="report_filters[filter.fieldname]" @change="handleCustFilterChange(filter, $event)"
					v-if="filter.fieldtype == 'Select'" style="width: 120px;padding-right: 6px;" clearable
					:placeholder="filter.label">
					<el-option v-for="item in filter.options" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>

				<el-select v-model="report_filters[filter.fieldname]" v-if="filter.fieldtype == 'Link'" filterable
					remote clearable reserve-keyword :placeholder="t('Search results for') + t(filter.options)"
					:remote-method="(query) => get_links(query, filter.options)" :loading="loading"
					@change="handleCustFilterChange(filter, $event)" remote-show-suffix style="width: 360px">
					<el-option v-for="item in link_results" :key="item.value" :label="item.value" :value="item.value">
						<span class="bold">{{ item.value }}</span>
						<span class="text-success" style="float: right;">{{ item.description }}</span>
					</el-option>
				</el-select>
				<el-switch v-if="filter.fieldtype == 'Check'" v-model="report_filters[filter.fieldname]"
					:active-text="filter.label" @change="handleCustFilterChange(filter, $event)"
					:inactive-text="filter.label" inline-prompt size="large" />

			</div>
			<el-switch v-model="show_charts" :active-text="t('Make Chart')" :inactive-text="t('Hide') + t('Chart')"
				inline-prompt size="large" />
			<el-button class="mx-2 my-2" size="small" type="primary" @click="get_report_data(report_filters)">
				<el-icon :size=16 :color="blue">
					<RefreshRight />
				</el-icon>
			</el-button>


		</div>

		<div v-show="report_loading" class="flex align-items-center justify-content-center"
			style="width:100%;height:600px;">
			<div class="is-loading">
				<svg class="circular" viewBox="0 0 200 200">
					<g class="path2 loading-path" stroke-width="0" style="animation: none; stroke: none">
						<circle r="33.75" class="dot1" rx="0" ry="0" />
						<circle r="33.75" class="dot2" rx="0" ry="0" />
						<circle r="33.75" class="dot4" rx="0" ry="0" />
						<circle r="33.75" class="dot3" rx="0" ry="0" />
					</g>
				</svg>
			</div>
		</div>



		<el-row v-if="report_data.report_summary" :gutter="16"
			class="text-center flex warp my-4 mx-2 bg-light p-4 rounded justify-content-center">
			<el-col style="min-width:240px;" v-for="sumary_item in report_data.report_summary"
				:span="get_span(report_data.report_summary)">
				<div class="statistic-card">
					<el-statistic :value="sumary_item.value" :value-style="{ color: sumary_item.indicator }">
						<template #title>
							<div style="display: inline-flex; align-items: center">
								{{ sumary_item.label }}
								<el-tooltip effect="dark" :content="getTooltipContent(sumary_item)" placement="top">
									<el-icon style="margin-left: 4px" :size="12">
										<Warning />
									</el-icon>
								</el-tooltip>
							</div>
						</template>
					</el-statistic>
				</div>
			</el-col>

		</el-row>


		<div :style="{ display: show_charts ? 'block' : 'none' }" class="my-2 echarts" :ref="setChartRef"
			style="width:100%;height:600px;">?</div>


		<el-table class="my-2" row-key="date" :data="currentPageData.data" style="max-width: 100% !important" border
			highlight-current-row stripe show-summary scrollbar-always-on height="auto"
			:summary-method="(param) => getSummary(param, report_data.columns)">
			<!-- <el-table-column type="selection" :selectable="selectable" width="55" /> -->
			<el-table-column type="index" width="50" />
			<el-table-column v-for="col in report_data.columns" :key="col.fieldname" :prop="col.fieldname"
				:min-width="120" :align="col.fieldtype === 'Currency' ? 'right' : 'left'" :label="t(col.label)"
				sortable>
				<template v-slot="scope">
					<span
						v-if="col.fieldname && scope.row[col.fieldname] !== undefined && !col.fieldname.includes('status') && col.fieldtype == 'Data'">
						{{ getFormatter(col.fieldtype, scope.row[col.fieldname]) }}
					</span>
					<span v-else-if="col.fieldname && col.fieldname.includes('status') && scope.row[col.fieldname]"
						class="indicator-pill filterable no-indicator-dot ellipsis"
						:class="guessClass(scope.row[col.fieldname])"
						:title="'Document is in ' + (scope.row[col.fieldname] || 'unknown') + ' state'">
						<span class="ellipsis">{{ t(scope.row[col.fieldname] || 'default status')
							}}</span>
					</span>
					<span v-else-if="col.fieldtype == 'Currency'">
						{{ getFormatter(col.fieldtype, scope.row[col.fieldname]) }}
					</span>
					<div v-else-if="col.fieldtype == 'Percent'">
						<el-progress :color="get_progress_color(scope.row[col.fieldname])" :text-inside="true"
							:stroke-width="26" :percentage="getPercent(col.fieldtype, scope.row[col.fieldname])" />
					</div>
					<div v-else-if="col.fieldtype == 'Link'">
						<el-link :href="get_url(col.fieldtype, scope.row[col.fieldname], col.options)" target="_blank">{{ scope.row[col.fieldname] }}</el-link>
					</div>
					<span v-else>
						{{ scope.row[col.fieldname] }}
					</span>
				</template>
			</el-table-column>

		</el-table>

		<div class="flex align-items-center justify-content-center">
			<el-pagination v-if="report_data.result?.length > 0" background v-model:page-size="currentSize"
				:current-page="currentPage" :page-sizes="[10, 50, 100, 200, 300, 400]"
				@current-change="handleCurrentChange" @size-change="handleSizeChange"
				:total="report_data.result?.length || 0" layout="total, sizes, prev, pager, next, jumper" />
		</div>

	</div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue';
import {
	getFormatter,
	t,
	shortcuts,
	disabledDate,
	guessClass,
	get_span,
	getTooltipContent,
	getSummary,
	get_url
} from '../base/vue_utils.js'
import {set_report_chart}  from '../query_report.js'
const currentPage = ref(1)
const currentPageData = reactive([])
const currentSize = ref(20)

// pagination
const handleSizeChange = (val) => {
	currentSize.value = val
	get_current_page_data()
}
const handleCurrentChange = (val) => {
	currentPage.value = val
	get_current_page_data()
}

const get_current_page_data = () => {
	currentPageData.data = reactive(report_data.result?.slice((currentPage.value - 1) * currentSize.value, currentPage.value * currentSize.value))
}
const show_charts = ref(true)
const report_data = reactive({});
const report_filters = reactive({})
let custom_filters = reactive([])
const loading = ref(false)
const report_loading = ref(false)
const handleCustFilterChange = (filter, selectedValue) => {
	report_loading.value = true
	setTimeout(() => {
		get_report_data(report_filters)
	}, 20)

}
const chartRefs = ref(null)
const setChartRef = (el) => {
	chartRefs.value = el;
};
// 定义 filters 和 report_name props
const { filters, report_name } = defineProps({
	filters: {
		type: Array,
		required: true
	},
	report_name: {
		type: String,
		required: true
	}
});
const link_results = ref()
const get_links = (query, doctype) => {
	link_results.value = []
	loading.value = true
	setTimeout(() => {

		frappe.call({
			method: "frappe.desk.search.search_link",
			args: {
				txt: query,
				doctype: doctype,
				reference_doctype: '',
				filters: {}
			},
			callback: (r) => {

				link_results.value = r.message
				loading.value = false
			}
		})
	}, 200)
}
let chart_options = reactive({})
const get_report_data = (filters) => {
	if(!filters){
		filters = report_filters
	}
	frappe.call({
		method: "frappe.desk.query_report.run",
		args: {
			report_name: report_name,
			filters: filters,
		},
		callback: (r) => {
			if (frappe.user.has_role('System Manager')) {
				console.log(r.message)
			}
			if(r.message.add_total_row && r.message.result?.length > 0) {
				// 原数据中包含合计列，删除
				r.message.result.pop()
			}
			report_loading.value = false
			report_data.result = r.message.result;
			report_data.columns = r.message.columns;
			report_data.message = r.message;
			report_data.chart = r.message.chart;
			report_data.report_summary = r.message.report_summary;
			get_current_page_data()
			if (r.message.chart) {
				chart_options = reactive(r.message.chart)
				setTimeout(() => {
					set_report_chart(chart_options, chartRefs.value)
				}, 200)
			}
		}
	})
}

setTimeout(() => {
	get_report_data(report_filters)
}, 20)
onMounted(() => {
	filters.map((filter) => {
		if (filter.default) {
			report_filters[filter.fieldname] = filter.default
		} else if (filter.fieldtype == 'Select' && filter.options) {
			report_filters[filter.fieldname] = filter.options[0].value || filter.options[0];
		}
	});
});
</script>

<style>
.filters {
	display: flex;
	width: 100%;
	padding: 0;
	flex-wrap: wrap;
	margin-top: 16px;
	margin-bottom: 16px;
	align-items: center;
}
</style>