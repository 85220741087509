<template>
	<div id="menu" class="flex" v-show=false>
		<div class="flex align-items-center justify-content-center" style="background-color: #545c64;">
			<a class="mx-4" target="_blank" href="/app"> <img style="width:40px;" :src="app_logo"
					alt="应用图标"> </a>
		</div>
		<el-menu :default-active="activeIndex2" class="el-menu-demo w-100" mode="horizontal" background-color="#545c64"
			text-color="#fff" active-text-color="#ffd04b" @select="handleSelect">
			<template v-for="(item, index) in menu_list">
				<el-sub-menu v-if="has_sub_menu(item)" :index="`${index + 1}`" :key="index">
					<template #title>{{ t(item.label) }}</template>
					<el-menu-item v-for="(sub_item, sub_index) in item.sub_menu" :key="sub_index"
						:index="`${index + 1}-${sub_index + 1}`">
						{{ t(sub_item.label) }}
					</el-menu-item>
				</el-sub-menu>
				<el-menu-item v-if="!has_sub_menu(item)" :index="`${index + 1}`"
					:key="index">
					{{ t(item.label) }}
				</el-menu-item>
			</template>
		</el-menu>
	</div>
	<div class="bg-white rounded px-2 shadow min-vh-100" style="max-width:100vw !important">

		<div id="content" style="">
			<div class="filters my-2">
				<el-date-picker class="block mx-2 my-2" style="max-width: 160px;" v-model="from_date" type="date"
					placeholder="From Date" :disabled-date="disabledDate" format="YYYY-MM-DD" @change="get_report_data"
					:shortcuts="shortcuts" />

				<el-date-picker class="block mx-2 my-2" style="max-width: 160px;padding-right: 6px;" v-model="to_date"
					type="date" placeholder="To Date" :disabled-date="disabledDate" format="YYYY-MM-DD"
					@change="get_report_data" :shortcuts="shortcuts" />

				<el-select class="block mx-2 my-2" v-model="business_division" filterable clearable
					:placeholder="t('Business Division')" style="width: 120px;padding-right: 6px;"
					@change="get_report_data">
					<el-option v-for="item in business_division_options" :key="item.value" :label="item.label"
						:value="item.value" />
				</el-select>

				<el-select class="block mx-2 my-2" v-model="project" filterable remote reserve-keyword clearable
					:placeholder="t('Search results for') + t('Project')"
					:remote-method="(query) => get_links(query, 'Project')" :loading="loading" @change="get_report_data"
					remote-show-suffix style="width: 240px;padding-right: 6px;">
					<el-option v-for="item in link_results" :key="item.value" :label="item.value"
						:style="'height: ' + get_desc_height(item.description)" :value="item.value">
						<div class="bold">{{ item.value }}</div>
						<div class="text-black-50" style="font-size:12px;"
							v-html="formatDescription(item.value, item.description)">
						</div>
					</el-option>
				</el-select>

				<el-select class="block mx-2 my-2" v-model="statistical_stage" filterable remote reserve-keyword clearable
					:placeholder="t('Search results for') + t('Statistical Stage')"
					:remote-method="(query) => get_links(query, 'Statistical Stage')" :loading="loading"
					@change="get_report_data" remote-show-suffix style="width: 240px;padding-right: 6px;">
					<el-option v-for="item in link_results" :key="item.value" :label="item.value"
						:style="'height: ' + get_desc_height(item.description)" :value="item.value">
						<div class="bold">{{ item.value }}</div>
						<div class="text-black-50" style="font-size:12px;"
							v-html="formatDescription(item.value, item.description)">
						</div>
					</el-option>
				</el-select>

				<el-select class="block mx-2 my-2" v-model="company" filterable remote reserve-keyword clearable
					:placeholder="t('Search results for') + t('Company')"
					:remote-method="(query) => get_links(query, 'Company')" :loading="loading" @change="get_report_data"
					remote-show-suffix style="width: 360px">
					<el-option v-for="item in link_results" :key="item.value" :label="item.value"
						:style="'height: ' + get_desc_height(item.description)" :value="item.value">
						<div class="bold">{{ item.value }}</div>
						<div class="text-black-50" style="font-size:12px;"
							v-html="formatDescription(item.value, item.description)">
						</div>
					</el-option>
				</el-select>
			</div>

			<el-tabs v-model="activeName" stretch type="border-card" class="demo-tabs mx-2" @tab-click="handleClick">
				<el-tab-pane v-for="tab in tabs" :key="tab.value" :label="tab.label" :value="tab.value">
					<div v-if="tab.value != 'summary'">
						<div class="filters my-2">
							<div v-for="filter in tab.custom_filters">

								<el-date-picker 
									v-model="custom_filters[filter.fieldname]" class="block mx-2 my-2" style="max-width: 160px;"  type="date" clearable
									@change="handleCustFilterChange(filter, $event)"  v-if="filter.fieldtype == 'Date'"
									:placeholder="custom_filters[filter.label]" format="YYYY-MM-DD" 
									:shortcuts="shortcuts" />

								<el-select v-model="custom_filters[filter.fieldname]"
									@change="handleCustFilterChange(filter, $event)" v-if="filter.fieldtype == 'Select'"
									style="width: 120px;padding-right: 6px;" clearable :placeholder="filter.label">
									<el-option v-for="item in filter.options" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-select>
								<el-select v-model="selected_custom_filter[filter.fieldname]" v-if="filter.fieldtype == 'Link'" filterable remote clearable
									reserve-keyword :placeholder="t('Search results for') + t(filter.options)"
									:remote-method="(query) => get_custom_filter_links(query, filter.options,filter.fieldname)" :loading="loading"
									@change="handleCustFilterChange(filter, $event)" remote-show-suffix style="width: 360px">
									<el-option v-for="item in custom_link_result[filter.fieldname]" :key="item.value" :label="item.value"
										:value="item.value">
										<span class="bold">{{ item.value }}</span>
										<span class="text-success" style="float: right;">{{ item.description }}</span>
									</el-option>
								</el-select>
								<el-switch v-if="filter.fieldtype == 'Check'" v-model="custom_filters[filter.fieldname]"
									:active-text="filter.label" @change="handleCustFilterChange(filter, $event)"
									:inactive-text="filter.label" inline-prompt size="large" />
							</div>
							<el-switch v-if="tab.report_name && report_data.result?.length > 0" v-model="show_details"
								:active-text="t('Show Details')" :inactive-text="t('Hide Details')" inline-prompt
								size="large" />
						</div>
						<div v-show="report_loading" class="flex align-items-center justify-content-center"
							style="width:100%;height:600px;">
							<div class="is-loading">
								<svg class="circular" viewBox="0 0 200 200">
									<g class="path2 loading-path" stroke-width="0" style="animation: none; stroke: none">
										<circle r="33.75" class="dot1" rx="0" ry="0" />
										<circle r="33.75" class="dot2" rx="0" ry="0" />
										<circle r="33.75" class="dot4" rx="0" ry="0" />
										<circle r="33.75" class="dot3" rx="0" ry="0" />
									</g>
								</svg>
							</div>
						</div>
						<div v-show="!report_loading">
							<div v-if="tab.report_name" class="flex">
								数据来源:<el-link :href="'/app/query-report/' + encodeURIComponent(tab.report_name)"
									target="_blank" type="primary">{{ t(tab.report_name) }}
								</el-link>
							</div>

							<div v-if="report_data.message" v-html="report_data.message[0]"></div>

							<el-row v-if="report_data.report_summary" :gutter="16"
								class="text-center flex warp my-4 bg-light p-4 rounded justify-content-center">
								<el-col style="min-width:240px;" v-for="sumary_item in report_data.report_summary"
									:span="get_span()">
									<div class="statistic-card">
										<el-statistic :value="sumary_item.value"
											:value-style="{ color: sumary_item.indicator }">
											<template #title>
												<div style="display: inline-flex; align-items: center">
													{{ sumary_item.label }}
													<el-tooltip effect="dark" :content="getTooltipContent(sumary_item)"
														placement="top">
														<el-icon style="margin-left: 4px" :size="12">
															<Warning />
														</el-icon>
													</el-tooltip>
												</div>
											</template>
										</el-statistic>
										<!-- <div class="statistic-footer">
											<div class="footer-item">
												<span class="text-small">同比</span>
												<span class="text-success">
													24%
													<el-icon>
														<CaretTop />
													</el-icon>
												</span>
											</div>
										</div> -->
									</div>
								</el-col>

							</el-row>

							<div class="my-2 echarts" v-if="report_data.chart?.data.labels.length > 0" :ref="el => setChartRef(el, tab.value)"
								style="width:100%;height:600px;">?</div>

							
							<el-table class="my-2" v-if="show_details" row-key="date" :data="currentPageData.data"
								style="max-width: 100% !important" border highlight-current-row stripe show-summary
								scrollbar-always-on height="auto" :summary-method="getSummary">
								<!-- <el-table-column type="selection" :selectable="selectable" width="55" /> -->
								<el-table-column type="index" width="50" fixed="left"/>
								<el-table-column v-for="col in report_data.columns" :key="col.fieldname"
									show-overflow-tooltip
									:prop="col.fieldname" :min-width="120"
									:width="col.width"
									:fixed="col.fieldname == 'Project'? 'left': col.fieldname == 'Net Profit' ? 'right':false"
									:align="col.fieldtype === 'Currency' ? 'right' : 'left'" :label="t(col.label)" sortable>
									<template v-slot="scope">
										<span
											v-if="col.fieldname && scope.row[col.fieldname] !== undefined && !col.fieldname.includes('status') && col.fieldtype == 'Data'">
											{{ getFormatter(col.fieldtype, scope.row[col.fieldname]) }}
										</span>
										<span
											v-else-if="col.fieldname && col.fieldname.includes('status') && scope.row[col.fieldname]"
											class="indicator-pill filterable no-indicator-dot ellipsis"
											:class="guessClass(scope.row[col.fieldname])"
											:title="'Document is in ' + (scope.row[col.fieldname] || 'unknown') + ' state'">
											<span class="ellipsis">{{ t(scope.row[col.fieldname] || 'default status')
												}}</span>
										</span>
										<span v-else-if="col.fieldtype == 'Currency'">
											{{ getFormatter(col.fieldtype, scope.row[col.fieldname]) }}
										</span>
										<div v-else-if="col.fieldtype == 'Percent'">
											<el-progress :color="get_progress_color(scope.row[col.fieldname])"
												:text-inside="true" :stroke-width="26"
												:percentage="getPercent(col.fieldtype, scope.row[col.fieldname])" />
										</div>
										<div v-else-if="col.fieldtype == 'Link'">
											<el-link :href="get_url(col.fieldtype, scope.row[col.fieldname], col.options)" target="_blank">{{ scope.row[col.fieldname] }}</el-link>
										</div>
										<span v-else>
											{{ scope.row[col.fieldname] }}
										</span>
									</template>
								</el-table-column>

							</el-table>
							<div class="flex align-items-center justify-content-center">
								<el-pagination
									v-if="show_details && report_data.result?.length > 0"
									background 
									v-model:page-size="currentSize"
									:current-page="currentPage"
									:page-sizes="[10,50,100, 200, 300, 400]"
									@current-change="handleCurrentChange"
									@size-change="handleSizeChange"
									:total="report_data.result?.length || 0"
									layout="total, sizes, prev, pager, next, jumper"
								/>
							</div>
						</div>
					</div>
					<div v-if="tab.value == 'summary'">
						<!-- <div class="filters my-2">
							<div v-for="filter in tab.custom_filters">
								<el-date-picker 
									v-model="custom_filters[filter.fieldname]" class="block mx-2 my-2" style="max-width: 160px;"  type="date"
									@change="handleCustFilterChange(filter, $event)"  v-if="filter.fieldtype == 'Date'"
									:placeholder="custom_filters[filter.label]" format="YYYY-MM-DD" 
									:shortcuts="shortcuts" />

								<el-select v-model="custom_filters[filter.fieldname]"
									@change="handleCustFilterChange(filter, $event)" v-if="filter.fieldtype == 'Select'"
									style="width: 120px;padding-right: 6px;" clearable :placeholder="filter.label">
									<el-option v-for="item in filter.options" :key="item.value" :label="item.label"
										:value="item.value" />
								</el-select>
								<el-select v-model="link_results" v-if="filter.fieldtype == 'Link'" filterable remote
									reserve-keyword :placeholder="t('Search results for') + t(filter.options)"
									:remote-method="(query) => get_links(query, filter.options)" :loading="loading"
									@change="get_report_data" remote-show-suffix style="width: 360px">
									<el-option v-for="item in link_results" :key="item.value" :label="item.description"
										:value="item.value">
										<span class="bold">{{ item.value }}</span>
										<span class="text-success" style="float: right;">{{ item.description }}</span>
									</el-option>
								</el-select>
								<el-switch v-if="filter.fieldtype == 'Check'" v-model="custom_filters[filter.fieldname]"
									:active-text="filter.label" @change="get_summary_data()"
									:inactive-text="filter.label" inline-prompt size="large" />
							</div>
						</div>

						<el-row v-if="report_data.report_summary" :gutter="16"
							class="text-center flex warp my-4 bg-light p-4 rounded justify-content-center">
							<el-col style="min-width:240px;" v-for="sumary_item in report_data.report_summary"
								:span="get_span()">
								<div class="statistic-card">
									<el-statistic :value="sumary_item.value"
										:value-style="{ color: sumary_item.indicator }">
										<template #title>
											<div style="display: inline-flex; align-items: center">
												{{ sumary_item.label }}
												<el-tooltip effect="dark" :content="getTooltipContent(sumary_item)"
													placement="top">
													<el-icon style="margin-left: 4px" :size="12">
														<Warning />
													</el-icon>
												</el-tooltip>
											</div>
										</template>
									</el-statistic>
								</div>
							</el-col>

						</el-row>

						<el-table class="my-2"  row-key="project" :data="currentPageData.data"
							style="max-width: 100% !important" border highlight-current-row stripe show-summary
							scrollbar-always-on height="auto" :summary-method="getSummary">
							<el-table-column type="index" width="50" />
							<el-table-column v-for="col in summary_data.columns" :key="col.fieldname"
								:prop="col.fieldname" :min-width="120"
								:align="col.fieldtype === 'Currency' ? 'right' : 'left'" :label="t(col.label)" sortable>
								<template v-slot="scope">
									<span
										v-if="col.fieldname && scope.row[col.fieldname] !== undefined && !col.fieldname.includes('status') && col.fieldtype == 'Data'">
										{{ getFormatter(col.fieldtype, scope.row[col.fieldname]) }}
									</span>
									<span
										v-else-if="col.fieldname && col.fieldname.includes('status') && scope.row[col.fieldname]"
										class="indicator-pill filterable no-indicator-dot ellipsis"
										:class="guessClass(scope.row[col.fieldname])"
										:title="'Document is in ' + (scope.row[col.fieldname] || 'unknown') + ' state'">
										<span class="ellipsis">{{ t(scope.row[col.fieldname] || 'default status')
											}}</span>
									</span>
									<span v-else-if="col.fieldtype == 'Currency'">
										{{ getFormatter(col.fieldtype, scope.row[col.fieldname]) }}
									</span>
									<div v-else-if="col.fieldtype == 'Percent'">
										<el-progress :color="get_progress_color(scope.row[col.fieldname])"
											:text-inside="true" :stroke-width="26"
											:percentage="getPercent(col.fieldtype, scope.row[col.fieldname])" />
									</div>
									<div v-else-if="col.fieldtype == 'Link'">
										<el-link :href="get_url(col.fieldtype, scope.row[col.fieldname], col.options)" target="_blank">{{ scope.row[col.fieldname] }}</el-link>
									</div>
									<span v-else>
										{{ scope.row[col.fieldname] }}
									</span>
								</template>
							</el-table-column>

						</el-table>
						<div class="flex align-items-center justify-content-center">
							<el-pagination
								v-if="summary_data.data?.length > 0"
								background 
								v-model:page-size="currentSize"
								:current-page="currentPage"
								:page-sizes="[10,50,100, 200, 300, 400]"
								@current-change="handleCurrentChange"
								@size-change="handleSizeChange"
								:total="summary_data.data?.length || 0"
								layout="total, sizes, prev, pager, next, jumper"
							/>
						</div>
						<div>{{ t('Time Taken') }}:{{summary_data.elapsed_time}}{{t('In seconds')}}</div> -->

					</div>
				</el-tab-pane>


			</el-tabs>

		</div>

	</div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue';
import { 
	getFormatter, 
	t, 
	shortcuts,
	disabledDate,
	menu_list,
	business_division_options,
	tabs,
	status_color_map,
	formatCurrency,
	set_chart_option,
	guessClass,
	get_url
} from '../base/vue_utils.js'
// 定义公共过滤条件变量
const from_date = ref(erpnext.utils.get_fiscal_year(frappe.datetime.get_today(), true)[1])
const to_date = ref(frappe.datetime.get_today())
const link_results = ref([])
const custom_link_result = reactive({})
const selected_custom_filter = ref({})
const business_division = ref('')
const user = frappe.session.user[0].toUpperCase()
const project = ref([])
const loading = ref(false)
const report_loading = ref(false)
const company = ref('')
const statistical_stage = ref('')
const app_logo = ref('')
const activeName = ref(0);

// pagination
const currentPage = ref(1)
const currentPageData = reactive([])
const currentSize = ref(10)

// 菜单测试
const activeIndex = ref('1')
const activeIndex2 = ref('1')

let chart_options = reactive({})
const chart = ref()
const chartRefs = reactive({})

const summary_data = reactive({})
const setChartRef = (el, value) => {
	if (el) {
		chartRefs[value] = el;
	}
};

const has_sub_menu = (item) => {
	return item.sub_menu && item.sub_menu.length > 0
}
const current_menu = ref('')
const handleSelect = (key, keyPath) => {
	let page_name = frappe.get_route()[0];
	let menu = frappe.get_route()[1];
	let menu_item = menu_list[keyPath[0]-1]
	frappe.set_route(page_name, menu_item.value);
	current_menu.value = menu_item.value
}

const get_links = (query, doctype,filters) => {
	link_results.value = []
	loading.value = true
	setTimeout(() => {

		frappe.call({
			method: "frappe.desk.search.search_link",
			args: {
				txt: query,
				doctype: doctype,
				reference_doctype: '',
				filters: filters
			},
			callback: (r) => {
				link_results.value = r.message
				loading.value = false
			}
		})
	}, 200)
}

const get_custom_filter_links = (query, doctype,fieldname) => {
	custom_link_result[fieldname] = []
	loading.value = true
	setTimeout(() => {
		frappe.call({
			method: "frappe.desk.search.search_link",
			args: {
				txt: query,
				doctype: doctype,
				reference_doctype: '',
				filters: {}
			},
			callback: (r) => {
				custom_link_result[fieldname] = r.message
				loading.value = false
			}
		})
	}, 200)
}

const show_details = ref(true)
const report_data = ref({})
let custom_filters = reactive({})
const handleCustFilterChange = (filter, selectedValue) => {
	custom_filters[filter.fieldname] = selectedValue
	get_report_data()
}
const selectable = () => {
	return true
}

const handleClick = (tab, event) => {
	show_details.value = true
	report_loading.value = true
	// timeout is needed to ensure activeName is updated
	setTimeout(() => {
		if(activeName.value == 0) {
			// summary tab
			init_custom_filters()
			get_summary_data()
		} else {
			init_custom_filters()
			get_report_data()
		}
	}, 20)
};

// 初始化tabs中自定义过滤器
const init_custom_filters = (selectedValue) => {
	Object.keys(custom_filters).forEach(key => {
		delete custom_filters[key];
	});
	if (!activeName.value) return
	if (tabs[activeName.value].custom_filters?.length > 0) {
		tabs[activeName.value].custom_filters.forEach(item => {
			if (item.default) {
				custom_filters[item.fieldname] = item.default
			}
		})
	}
}
const get_filters = () => {
	let filters = {
		from_date: from_date.value,
		to_date: to_date.value,
		company: company.value,
		project: project.value,
		business_division: business_division.value,
		statistical_stage: statistical_stage.value,
	}
	if (custom_filters) {
		Object.assign(filters, custom_filters);
	}
	return filters
}
const get_report_data = () => {
	// report_loading.value = true
	if(activeName.value == 0) {
			// summary tab
			// init_custom_filters()
			get_summary_data()
			return
		}
	report_data.value = {}
	if (!activeName.value) return
	const reportName = tabs[activeName.value].report_name;
	if (!reportName) return
	let filters = get_filters()
	frappe.call({
		method: "frappe.desk.query_report.run",
		args: {
			report_name: reportName,
			filters: filters,
		},
		callback: (r) => {
			report_loading.value = false
			if(frappe.user.has_role('System Manager')) {
				console.log(r.message)
			}
			if (r.message.add_total_row && r.message.result?.length > 0) {
				r.message.result.pop()
			}
			report_data.value = r.message
			get_current_page_data()
			if (r.message.chart) {
				chart_options = reactive(r.message.chart)
				setTimeout(() => {
					set_chart_option(chart_options,activeName,chartRefs)
				}, 200)
			}
		}
	})
}

const get_summary_data = () => {
	report_loading.value = false
	summary_data.report_name = "Project Data Base On GL Entry"
	let filters = get_filters()
	filters.voucher_group = ""
	// filters.ignore_internal_customer = 1
	frappe.call({
		// method: "frappe.desk.query_report.run",
		method: "localization.localization.report.project_data_base_on_gl_entry.project_data_base_on_gl_entry.get_gl_data",
		args: {
			// report_name: summary_data.report_name,
			filters: filters,
		},
		callback: (r) => {
			report_loading.value = false
			if(frappe.user.has_role('System Manager')) {
				data_analysis(r.message)
			}
		
		}
	})
}

const data_analysis = (data) => {
	
	summary_data.data = data.result
	summary_data.columns = data.columns
	summary_data.elapsed_time = data.elapsed_time
	report_data.value = {
		result: summary_data.data,
		columns: summary_data.columns,
		add_total_row: false,
		report_summary: data.report_summary,
	}
	get_current_page_data()
	// currentPageData.data = reactive(summary_data.data?.slice((currentPage.value - 1) * currentSize.value, currentPage.value * currentSize.value))
}

// pagination
const handleSizeChange = (val) => {
	currentSize.value = val
	get_current_page_data()
}
const handleCurrentChange = (val) => {
	currentPage.value = val
	get_current_page_data()
}

const get_current_page_data = () => {
	currentPageData.data = reactive(report_data.value.result?.slice((currentPage.value - 1) * currentSize.value, currentPage.value * currentSize.value))
}

const getPercent = (fieldtype, cellValue) => {
	return flt(cellValue, 2)
}

const getTooltipContent=(item) => {
        return item.tips || item.label;
    }
const get_progress_color = (value) => {
	if (value > 35) {
		return 'green'
	} else if (value > 25) {
		return 'orange'
	} else if (value > 15) {
		return 'yellow'
	} else {
		return 'red'
	}
}

// 动态设置汇总跨度
const get_span = () => {
	if (!activeName.value) return 8
	else {
		let smmary_len = report_data.value.report_summary.length
		if (smmary_len <= 4 && smmary_len > 0) {
			return 24 / smmary_len
		} else {
			return 4
		}
	}
}
const formatDescription = (value, description) => {
	// 将逗号分隔的字符串转换为分行显示的 HTML
	if (!description) return `<div style="margin:-8px;"></div>`
	const parts = description.split(',').filter(part => part.trim() !== value);
	const html = parts.map(part => `<div style="margin:-8px;">${t(part.trim())}</div>`).join('');
	return html;
}
const get_desc_height = (description) => {
	if (!description) return '36px'
	let items = description.split(',').filter(part => part.trim() !== '');
	let desc_len = items.length
	return flt(desc_len * 18) + 36 + 'px'
}
const getSummary = (param) => {
	const { columns, data } = param;
	const sums = [];
	columns.forEach((column, index) => {
		if (index === 1) {
			sums[index] = t('Total');
			return;
		}
		// 获取当前列的配置信息
		const colConfig = report_data.value.columns?.find(col => col.fieldname === column.property);
		if (colConfig && colConfig.fieldtype === 'Currency') {
			const values = data.map(item => {
				const value = item[column.property];
				const parsedValue = parseFloat(value); // 尝试将值转换为数字
				return Number.isNaN(parsedValue) ? 0 : parsedValue; // 如果转换后的值是NaN，则返回0，否则返回转换后的值
			});
			const sum = values.reduce((acc, val) => acc + val, 0);
			sums[index] = formatCurrency(sum); // 格式化货币值
		} else {
			sums[index] = '';
		}
	});
	return sums;
}
const set_default_date = () => {
	let today = new Date();
	const currentMonth = new Date().getMonth();
	if (currentMonth == 0) {
		today.setFullYear(today.getFullYear() - 1)
		from_date.value = erpnext.utils.get_fiscal_year(frappe.datetime.get_datetime_as_string(today), true)[1]
		to_date.value = erpnext.utils.get_fiscal_year(frappe.datetime.get_datetime_as_string(today), true)[2]
	}
}

onMounted(() => {
	business_division.value = business_division_options[0].value
	let page_name = frappe.get_route()[0];
	frappe.set_route(page_name, menu_list[0].value);
	// init_chart(myChart)
	// activeName.value = 0
	// handleClick()
	// get_summary_data()
	set_default_date()

	frappe.db.get_single_value("Navbar Settings", "app_logo").then((value) => {
		app_logo.value = value
	})
});
</script>

<style scoped>
.filters {
	display: flex;
	width: 100%;
	padding: 0;
	flex-wrap: wrap;
	margin-top: 16px;
	margin-bottom: 16px;
	align-items: center;
	justify-content: flex-start;
}

.demo-tabs>.el-tabs__content {
	padding: 32px;
	color: #6b778c;
	font-size: 32px;
	font-weight: 600;
}

:root {
	--el-color-primary: #409EFF;
	/* 这里是您想要设置的主色调 */
}

.circular {
	display: inline;
	height: 30px;
	width: 30px;
	animation: loading-rotate 2s linear infinite;
}

.path {
	animation: loading-dash 1.5s ease-in-out infinite;
	stroke-dasharray: 90, 150;
	stroke-dashoffset: 0;
	stroke-width: 2;
	stroke: var(--el-color-primary);
	stroke-linecap: round;
}

.loading-path .dot1 {
	transform: translate(3.75px, 3.75px);
	fill: var(--el-color-primary);
	animation: custom-spin-move 1s infinite linear alternate;
	opacity: 0.3;
}

.loading-path .dot2 {
	transform: translate(calc(100% - 3.75px), 3.75px);
	fill: var(--el-color-primary);
	animation: custom-spin-move 1s infinite linear alternate;
	opacity: 0.3;
	animation-delay: 0.4s;
}

.loading-path .dot3 {
	transform: translate(3.75px, calc(100% - 3.75px));
	fill: var(--el-color-primary);
	animation: custom-spin-move 1s infinite linear alternate;
	opacity: 0.3;
	animation-delay: 1.2s;
}

.loading-path .dot4 {
	transform: translate(calc(100% - 3.75px), calc(100% - 3.75px));
	fill: var(--el-color-primary);
	animation: custom-spin-move 1s infinite linear alternate;
	opacity: 0.3;
	animation-delay: 0.8s;
}

@keyframes loading-rotate {
	to {
		transform: rotate(360deg);
	}
}

@keyframes loading-dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -40px;
	}

	100% {
		stroke-dasharray: 90, 150;
		stroke-dashoffset: -120px;
	}
}

@keyframes custom-spin-move {
	to {
		opacity: 1;
	}
}
</style>