
	frappe.templates['crm_notes'] = `<div class="notes-section col-xs-12">
	<div class="new-btn pb-3">
		<button class="btn btn-sm small new-note-btn mr-1">
			<svg class="icon icon-sm">
				<use href="#icon-add"></use>
			</svg>
			{{ __("New Note") }}
		</button>
	</div>
	<div class="all-notes">
		{% if (notes.length) { %}
			{% for(var i=0, l=notes.length; i<l; i++) { %}
				<div class="comment-content p-3 row" name="{{ notes[i].name }}">
					<div class="mb-2 head col-xs-2">
						{% if (notes[i].added_by && notes[i].added_on) %}
						<div class="row">
							<div class="col-xs-2">
								{{ frappe.avatar(notes[i].added_by) }}
							</div>
							<div class="col-xs-10">
								<div class="mr-2 title font-weight-bold ellipsis" title="{{ strip_html(notes[i].added_by) }}">
									{{ strip_html(notes[i].added_by) }}
								</div>
								<div class="time small text-muted">
									{{ frappe.datetime.get_datetime_as_string(notes[i].added_on) }}
								</div>
							</div>
						</div>
						{% } %}
					</div>
					<div class="content col-xs-9">
						{{ notes[i].note }}
					</div>
					<div class="col-xs-1 text-right">
						<span class="edit-note-btn btn btn-link">
							<svg class="icon icon-sm"><use xlink:href="#icon-edit"></use></svg>
						</span>
						<span class="delete-note-btn  btn btn-link pl-2">
							<svg class="icon icon-xs"><use xlink:href="#icon-delete"></use></svg>
						</span>
					</div>
				</div>
			{% } %}
		{% } else { %}
            <div class="no-activity text-muted pt-6">
                {{ __("No Notes") }}
            </div>
		    {% } %}
	</div>
</div>

<style>

.comment-content {
    border: 1px solid var(--border-color);
	border-bottom: none;
}

.comment-content:last-child {
    border-bottom: 1px solid var(--border-color);
}

.new-btn {
	text-align: right;
}

.notes-section .no-activity {
	min-height: 100px;
	text-align: center;
}

.notes-section .btn {
	padding: 0.2rem 0.2rem;
}

</style>`;
