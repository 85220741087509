import { createApp, h } from 'vue';
import ElementPlus from 'element-plus';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import App from './vue_report.vue'

frappe.vue_report_view = class VueReport {
	constructor(el, report_name, filters) {
		
		this.init(el, report_name, filters);
	}

	init(el, report_name, filters) {
		// console.log(el, report_name, filters)
		const app = createApp(App,
			{ filters, report_name }
		);

		app.use(ElementPlus, {
			locale: zhCn,
		});

		for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
			app.component(key, component);
		}

		// Manually mount the app to the element
		app.mount(el);
		// console.log(app)
	}
}